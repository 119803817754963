body, html {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 85%;
  display: flex;
}

#perso_section {
  background-color: #3b3a3f;
  border-color: #3b3a3f;
  border-right-style: solid;
  border-right-width: 10px;
  flex-direction: column;
  flex: none;
  margin: 0;
  padding: 0 0 0 16px;
}

#pro_section {
  color: #3b3a3f;
  background-color: #fff;
  flex: auto;
  justify-content: space-between;
  min-height: 100vh;
  padding: 0 15px;
  display: flex;
}

ul {
  margin: 8px;
  padding: 0 16px;
}

td p {
  margin: 4px 0;
}

#perso_section a {
  color: #e4e4e4;
}

#name {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

#job_title, #name, #pix {
  text-align: center;
}

#job_title {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #1fb6b1;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: normal;
}

#pix > img {
  border: 1px solid #ccc;
  max-width: 160px;
  max-height: 160px;
  margin: 10px auto auto;
}

#perso_section .column_title, #perso_section table {
  color: #fff;
}

#perso_section .column_title {
  border-bottom: 1px solid #1fb6b1;
  margin-top: 25px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

#perso_section .entry_title {
  font-weight: 700;
}

#perso_section .entry_content {
  color: #ccc;
}

#perso_section table tr:nth-child(odd) td {
  padding-top: 15px;
}

#pro_section #content {
  flex-direction: column;
  height: 100%;
  display: flex;
}

#pro_section .column_title {
  color: #3b3a3f;
  border-bottom: 1px solid #1fb6b1;
  margin-top: 25px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  font-size: 28px;
}

#pro_section #education, #pro_section #jobs {
  width: 100%;
}

#pro_section #jobs tr:nth-child(odd) td {
  padding: 15px 0 5px;
}

#pro_section #education tr:nth-child(odd) td {
  padding: 5px 0 0;
}

#pro_section #jobs tr:nth-child(2n) td {
  padding: 0 0 15px;
}

#pro_section #education tr:nth-child(2n) td {
  padding: 0 0 5px;
}

#hobbies tr td:first-child ul, #pro_section #education tr td:first-child ul, #pro_section #jobs tr td:first-child ul {
  color: #1fb6b1;
  margin: 0 0 0 5px;
  padding: 0;
}

.work_date, .work_name {
  font-weight: 700;
}
/*# sourceMappingURL=web.debdbb0e.css.map */
